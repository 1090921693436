import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section1.module.scss'
import { logo, smallBuildings } from '@images'
import { Fade, Reveal } from 'react-reveal'

const Section1 = props => {
  const { className, children, title, id, buttons } = props
  return (<div id={id} className={[className, styles.container].join(' ')}>
    <div className={['row', styles.centered].join(' ')}>
      <div className="col-sm-5 text-center">
        <Fade bottom>
          <img className={styles.logo} src={logo} alt="tsplus application screenshot"/>
        </Fade>
      </div>
      <div className={['col-sm-7', styles.contentWrapper].join(' ')}>
        <Fade big top>
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>
            {children}
          </div>
          {buttons}
        </Fade>
      </div>
    </div>
    <div className={styles.shapes}>
      <div className={styles.image}>
        <Reveal>
          <img src={smallBuildings} alt="small buildings background"/>
        </Reveal>
      </div>
    </div>
  </div>)
}

Section1.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any,
  id: PropTypes.string
}

export default Section1
