import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section4.module.scss'
import { logo, serverGeniusSection, smallBuildings } from '@images'
import { Fade, Reveal } from 'react-reveal'


const Section2 = props => {
  const { className, children, title, id, buttons } = props
  return (<div id={id} className={[className, styles.container].join(' ')}>
    <div className={['row', styles.centered].join(' ')}>
      <div className="col-sm-7">
        <Fade top big>
          <img className={['d-none d-sm-inline mb-3'].join(' ')} src={logo} alt="tsplus logo" width="232"/>
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>
            {children}
          </div>
          {buttons}
        </Fade>
      </div>
      <div className="col-sm-4 d-none d-md-block">
        <Fade bottom>
          <img className={styles.securityImage} src={serverGeniusSection} alt="tsplus application screenshot"/>
        </Fade>
      </div>
    </div>

    <div className={styles.shapes}>
      <div className={styles.image}>
        <Reveal>
          <img src={smallBuildings} alt="small buildings background"/>
        </Reveal>
      </div>
    </div>
  </div>)
}

Section2.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any,
  id: PropTypes.string,
  language: PropTypes.string
}

export default Section2
