
import React from 'react'
import styles from './GroupButtons.module.scss'

const GroupButtons = props => {
  const { children, className } = props
  return (<div className={[styles.container, className]}>
    {children}
  </div>)
}

export default GroupButtons
