import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@layouts/Layout'
import { useIntl } from 'gatsby-plugin-intl'
import { Section1, Section2, Section3, Section4, Section5 } from '@templates/downloads'
import Button from '@components/buttons/Button'
import GroupButtons from '@components/group_buttons/GroupButtons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple, faGooglePlay, faMicrosoft } from '@fortawesome/free-brands-svg-icons'
import { settings } from '@app/settings'

const Download = props => {
  const { pageContext } = props
  const { languageName } = pageContext
  const {
    setupProgam,
    updateReleasePatch,
    ultimateProtection,
    serverMonitoring,
    appleApp,
    androidApp,
    microsoftApp
  } = settings
  const { formatMessage } = useIntl()

  const ButtonDownload = (options) => {
    const { href } = options
    return (<GroupButtons>
      <Button href={href}>{formatMessage({ id: 'free-download' })}</Button>
    </GroupButtons>)
  }

  const Content = options => {
    const { id } = options
    return <div dangerouslySetInnerHTML={{ __html: formatMessage({ id }) }} />
  }

  const ButtonsApp = () => (<GroupButtons>
    <Button href={appleApp}>
      <FontAwesomeIcon icon={faApple}/>
    </Button>
    <Button href={microsoftApp}>
      <FontAwesomeIcon icon={faMicrosoft}/>
    </Button>
    <Button href={androidApp}>
      <FontAwesomeIcon icon={faGooglePlay}/>
    </Button>
  </GroupButtons>)

  return <Layout languageName={languageName} pageContext={pageContext}>
    <div className='row'>
      <Section1
        className='col-md-12'
        title='TSplus Setup program'
        id='setup-program'
        buttons={<ButtonDownload href={setupProgam}/>}
      >
        <Content id='download-section-1-content'/>
      </Section1>
      <Section2
        className='col-md-12'
        title='Update Release patch'
        id='update-release-patch'
        buttons={<ButtonDownload href={updateReleasePatch}/>}
      >
        <Content id='download-section-1-content'/>
      </Section2>
      <Section3
        className='col-md-12'
        title='TSplus Ultimate Protection'
        id='ultimate-protection'
        buttons={<ButtonDownload href={ultimateProtection}/>}
      >
        <Content id='download-section-1-content'/>
      </Section3>
      <Section4
        className='col-md-12'
        title='TSplus Server Monitoring'
        id='server-monitoring'
        buttons={<ButtonDownload href={serverMonitoring}/>}
      >
        <Content id='download-section-1-content'/>
      </Section4>
      <Section5
        className='col-md-12'
        title='TSplus Mobile App'
        id='mobile-app'
        buttons={<ButtonsApp/>}
      >
        <Content id='download-section-1-content'/>
      </Section5>
    </div>
  </Layout>
}

Download.propTypes = {
  pageContext: PropTypes.any
}

export default Download
